













































































































































































































































import {Component, Vue} from "vue-property-decorator";
import SaveW8BENRequestDTO from "@/dto/archive/SaveW8BENRequestDTO";
import RegexUtils from "@/utils/RegexUtils";
import DocumentService from "@/services/DocumentService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {namespace} from "vuex-class";
import PortalInput from "@/components/common/PortalInput.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import SaveW9RequestDTO from "@/dto/archive/SaveW9RequestDTO";
import AddressDTO from "@/dto/AddressDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import {HolderType} from "@/dto/HolderType";
import {BankAccountType} from "@/dto/BankAccountType";
import Application from "@/state/Application";
import {FillableDocumentStatus} from "@/dto/archive/FillableDocumentStatus";
import RouteNames from "@/router/RouteNames";

const AppModule = namespace("App");

@Component({
	components: {PortalInput}
})
export default class FormW8BEN extends Vue {

	private successful = false;

	private message = "";

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private request = new SaveW8BENRequestDTO();

	private mailingAddressIsDifferent = false;

	private exists = true;

	private processed = false;

	mounted(){
    if(this.$route.name == RouteNames.PUBLIC_FORMS_W8BEN) {
      this.request.publicId = this.$route.params.publicId;
      this.loadPublicForm();
    } else{
      this.request.id = Number.parseInt(this.$route.params.id);
      this.loadInternalForm();
    }
	}

  private loadPublicForm(): void{
    this.startLoading();
    DocumentService.loadPreparedPublicFormW8BEN(this.$route.params.publicId).then(
        success => {
          this.exists = true;
          this.stopLoading();
          if (success.data.status === FillableDocumentStatus.PENDING_SIGNATURE) {
            this.goToSignature(success.data.signerPublicId as string)
          } else {
            this.request = success.data;
          }
        },
        error => {
          this.exists = false;
          processError(error, this)
          this.successful = false;
          this.stopLoading();
        });
  }

	private loadInternalForm() {
    this.startLoading();
		DocumentService.loadPreparedFormW8BEN(Number.parseInt(this.$route.params.id)).then(
			success => {
        this.stopLoading();
        this.exists = true;
				this.request = success.data;
				this.request.id = Number.parseInt(this.$route.params.id);
				if (!this.request.permanentAddress) {
					this.request.permanentAddress = new AddressDTO();
				}
			},
			error => {
        this.stopLoading();
        processError(error, this)
      }
		);
	}

	handleName(event: KeyboardEvent) {
		if (event.key != '\'' && event.key != "-" && !RegexUtils.testLatin(event.key)) {
			event.preventDefault();
		}
	}

  private save(): void {
    if(this.$route.name === RouteNames.PUBLIC_FORMS_W8BEN) {
      this.request.publicId = this.$route.params.publicId;
      this.savePublicForm();
    } else {
      this.request.id = Number.parseInt(this.$route.params.id);
      this.saveInternalForm();
    }
  }

	saveInternalForm() {
		ifValid(this, () => {
			this.message = "";
			this.startLoading();
			DocumentService.saveW8BEN(this.request).then(
				success => {
          this.stopLoading()
					this.successful = true;
					this.processed = true;
          this.$router.push({name: RouteNames.ARCHIVE_W8BEN});
				},
				error => {
					processError(error, this);
          this.stopLoading()
				}
			)
		})
	}

  private savePublicForm(): void {
    ifValid(this, () => {
      this.message = "";
      this.startLoading();
      DocumentService.savePublicW8BEN(this.request).then(
          success => {
            this.stopLoading();
            this.successful = true;
            this.processed = true;
            this.goToSignature(success.data);
          },
          error => {
            processError(error, this);
            this.stopLoading();
          }
      )
    })
  }

	handleSsn(event: KeyboardEvent) {
		let current = this.request.usSsnOrItin ? this.request.usSsnOrItin : "";
		if (RegexUtils.testSsnPreSeparator(current)) {
			current = current.concat('-')
			this.request.usSsnOrItin = current;
		}
		const next = (current).concat(event.key)
		if (!RegexUtils.testSsn(next)) {
			event.preventDefault();
		}
		if(next.length > 11){
			event.preventDefault();
		}
	}

  private goToSignature(publicId: string): void {
    this.$router.push({name: RouteNames.SIGN_PUBLIC, params: { publicId }});
  }

}
