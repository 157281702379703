import AddressDTO from "@/dto/AddressDTO";
import {FillableDocumentStatus} from "@/dto/archive/FillableDocumentStatus";

export default class SaveW8BENRequestDTO {

    id: number | null = null;
    status: FillableDocumentStatus | null = null;
    sublimeFilling: boolean | null = null;
    signerPublicId: string | null = null;

    publicId = '';

    name = '';

    citizenship = '';

    permanentAddress = new AddressDTO();

    mailingAddress = new AddressDTO();

    usSsnOrItin = '';

    foreignTin = '';

    isFtinRequired = false;

    referenceNumbers = '';

    birthDay = '';

    residentship = '';

    articleAndParagraph = '';

    rate: number | null = null;

    typeOfIncome = '';

    additionalConditionsExplanation = '';

    hasCapacityToSign = false;

    signerName = "";

}